import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeGuard } from './guards/home.guard';
import { UserDataResolver } from './resolvers/user-data.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'calendar/appuntamenti',
    canActivate: [HomeGuard],
    resolve:{
      userData: UserDataResolver
    },
    pathMatch: 'full'
  },
  {
    path: 'calendar/:id',
    canActivate: [HomeGuard],
    resolve:{
      userData: UserDataResolver
    },
    loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'print/:entity/:id',
    canActivate: [HomeGuard],
    resolve:{
      userData: UserDataResolver
    },
    loadChildren: () => import('./print/print.module').then( m => m.PrintPageModule)
  },
  {
    path: 'modal-edit',
    canActivate: [HomeGuard],
    resolve:{
      userData: UserDataResolver
    },
    loadChildren: () => import('./modal-edit/modal-edit.module').then( m => m.ModalEditPageModule)
  },
  {
    path: 'modal-list',
    canActivate: [HomeGuard],
    resolve:{
      userData: UserDataResolver
    },
    loadChildren: () => import('./modal-list/modal-list.module').then( m => m.ModalListPageModule)
  },
  {
    path: 'list/:entity',
    canActivate: [HomeGuard],
    resolve:{
      userData: UserDataResolver
    },
    loadChildren: () => import('./list-page/list-page.module').then( m => m.ListPagePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  }
  /*,
  {
    path: 'edit/:model/:id',
    loadChildren: () => import('./edit/edit.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'list/:model/:id',
    loadChildren: () => import('./list/list.module').then( m => m.CalendarPageModule)
  }
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
