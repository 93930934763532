import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})

export class HomeGuard implements CanActivate {
  constructor(public storageService: StorageService, public router: Router) {}
  canActivate(): Promise<boolean> {
    return new Promise(resolve => {
      this.storageService
      .get('userData')
      .then(res => {
      if (res) {
        resolve(true);
      } else
      { 
        if(!location.pathname.includes('login')) {
          this.router.navigate(['login']);
        }  
        resolve(false);
      }
      
    })
    .catch(err => {
      if(!location.pathname.includes('login')) {
        this.router.navigate(['login']);
      }
      resolve(false);
      });
    });
  }
}