import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { RestService } from './services/rest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  entities = [];
  public appPages = [
    {
      title: 'Calendario',
      url: '/calendar/appuntamenti',
      icon: 'calendar'
    }/*,
    {
      title: 'Appuntamenti',
      url: '/list/appuntamenti',
      icon: 'list'
    },
    {
      title: 'Tipo appuntamento',
      url: '/list/tipo_appuntamento',
      icon: 'archive'
    },
    {
      title: 'Clienti',
      url: '/list/clienti',
      icon: 'people'
    }  */

  ];

  public labels = [/*'Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'*/];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private restService: RestService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
  
  convertName(name)
  {
    return this.restService.convertName(name);
  }

  async ngOnInit() {   

    if(!location.pathname.includes('login'))
    {      
      await this.restService.init();
      
      (await this.restService.getEntities())
      .subscribe(result => {
        this.entities = new Array(result);
        this.entities[0].forEach(entity => {  
          this.appPages.push({
            title: entity['table_name'],
            url: '/list/'+entity['table_name'],
            icon: 'list'
          });
        });
      });
    }
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
}
