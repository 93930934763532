import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AuthService } from '../services/auth.service';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  private allEntities; 
  private allFields; 

  constructor(
    private httpService: HttpService,
    private authService: AuthService,    
    private router: Router,
    ) {
    }

    async init()
    {
      await this.getListSync("appuntamenti");
      await this.getEntitiesSync();
    }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getToken() {    
    return this.authService.getToken();
  }
  
  private handleError(error, o) {
    if(error.status == 401)
    {
      location.href = '/login';
    }
    return null;
  }
    
  async getList(entity, filters = null) {   
    const token = await this.getToken()   
    let headers = this.httpService.getDefaultHeaders();
    if(token)
    {
      headers = headers.set('AuthToken', token);
    }
    const filterParam = filters ? '?filter=' + encodeURIComponent(JSON.stringify(filters)) : '';
    return this.httpService.get('items/' + entity + filterParam, headers).pipe(
      catchError(this.handleError));
  }

  async getListSync(entity, filters = null) {
    var list;
    var wait = true;
    (await this.getList(entity, filters)).subscribe(result => {
      list = result;
      wait = false;
    });
    while (wait)
    {
      await this.sleep(500);
    }
    return list;
  }

  async getAllEntities()
  {
    if(this.allEntities) return this.allEntities;
    await this.getEntitiesSync();
    return this.allEntities;
  }

  async getEntitiesSync() {
    var list;
    var wait = true;
    (await this.getEntities()).subscribe(result => {
      list = result;
      this.allEntities = result;
      wait = false;
    });
    while (wait)
    {
      await this.sleep(500);
    }
    return list;
  }

  async getEntities() {

    const token = await this.getToken()      
    let headers = this.httpService.getDefaultHeaders();
    if(token)
    {
      headers = headers.set('AuthToken', token);
    }
    return this.httpService.get('entities?all=true', headers).pipe(
      catchError(this.handleError)
    );
  }

  async getFieldsSync(entity) {
    
    if(this.allEntities && entity !== 'all')
    {
      const found_entity = this.allEntities.find(e => e['table_name'] === entity);
      return found_entity.fields;
    }

    
    var fields;
    var wait = true;
    (await this.getFields(entity)).subscribe(result => 
      {
        fields = result;
        wait = false;
      });
    while (wait)
    {
      this.sleep(500);
    }
    return fields;
  }
    
  async getFields(entity) {
    const token = await this.getToken()      
    let headers = this.httpService.getDefaultHeaders();
    if(token)
    {
      headers = headers.set('AuthToken', token);
    }
    return this.httpService.get(entity + '/fields/', headers).pipe(
      catchError(this.handleError));      
    
  }
  
  async getAllFields() {
    if(this.allFields)
    {
      return this.allFields;
    }
    this.allFields = await (await this.getFields('all')).toPromise();//.subscribe(result => { this.allFields = result; });
    return this.allFields;
  }
  
  async getChildEntities(entity) {
    const fields = (await this.getAllFields());    
    const found_fields = fields.filter(f => f.name === entity && f.entity !== entity);
    return found_fields.map(f => f.entity);
  }

  async getModel(entity, id) {
    const token = await this.getToken()     
    let headers = this.httpService.getDefaultHeaders();
    if(token)
    {
      headers = headers.set('AuthToken', token);
    }
    return this.httpService.get('items/' +  entity + '/' + id, headers);    
  }

  async getModelSync(entity, id) {
    var model;
    var wait = true;
    (await this.getModel(entity, id)).subscribe(result => {
      model = result;
      wait = false;
    });
    while (wait)
    {
      await this.sleep(500);
    }
    return model;
  }
  
  async saveItems(entity, model) {  
    const token = await this.getToken()    
    let headers = this.httpService.getDefaultHeaders();
    if(token)
    {
      headers = headers.set('AuthToken', token);
    }  
    return this.httpService.post('items/' +  entity + '/',
    model, headers);
  }

  //  ---------------- UTILITIES ------------------------

  isEntity(key)
  {
    return this.allEntities.find(entry => entry.table_name == key);
  }

  getType(fields, key)
  {
    if(key in fields)
    {
      const type = fields[key]['type'];
      const name = fields[key]['name'];
      const length = fields[key]['length'];
      if(key.toLowerCase() == "id")
      {
        return "id";
      }
      else if(this.isEntity(key))
      {
        return "entity";
      }
      else if(type == 'datetime' || type == 'timestamp')
      {      
        return "datetime";
      }
      else if(type == 'date')
      {      
        return "date";
      }
      else if(type == 'tinyint' && length == 1)
      {      
        return "bool";
      }    
      else if(type.startsWith('int'))
      {      
        return "int";
      }    
      else if(type.startsWith('decimal'))
      {      
        return "decimal";
      } 
      else if(type.startsWith('varchar') && length < 1000)
      {      
        return "string";
      }
      else if(type.startsWith('varchar') && length >= 1000 || type.startsWith('text'))
      {      
        return "text";
      }
    }
    return typeof key;
  }

  convertValue(type, value)
  {
    switch(type)
    {
      case "datetime": { 
        value = new Date(value).toISOString();
        break;
      }
      case "bool": { 
        value = value && value != '0' ? true : false;
        break;
      }
      case "entity": { 
        //this.model[key] = +this.model[key];
        break;
      }
    }
    return value;
  }

  deconvertValue(type, value) 
  {
    switch(type)
    {
      case "date":
      case "datetime": { 
        if(!(value) || value == '')
        {
          value = null;
        }
        else
        {
          const date = new Date(value);
          value = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.toLocaleTimeString();
        }
        break;
      }
      case "bool": { 
        value = value ? 1 : 0;
        break;
      }
      case "entity":
      case "int":
      case "decimal":
      { 
        value = value ? value : 0;
        break;
      }
    }
    return value;
  }

  convertName(name)
  {
    name = name.replace('_', ' ');
    name = name.charAt(0).toUpperCase() + name.slice(1);
    return name;
  }

  deconvertName(name)
  {
    name = name.replace('', '_');
    name = name.toLowerCase();
    return name;
  }


}
