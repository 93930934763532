import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
providedIn: 'root'
})
export class HttpService {
constructor(private http: HttpClient) {}

  post(serviceName: string, data: any, headers = null) {
    if(headers == null) 
    {
      headers = this.getDefaultHeaders();
    }
    const options = { headers: headers, withCredintials: false, mode:'cors' };
    const url = environment.apiUrl + serviceName;

    return environment.production ? this.http.post(url, JSON.stringify(data), options) : this.http.post(url, JSON.stringify(data));
  }

  get(serviceName: string, headers = null) {
    if(headers == null)
    {
      headers = this.getDefaultHeaders();
    }
    const options = { withCredentials: false, mode:'cors', headers: headers };
    const url = environment.apiUrl + serviceName;
    
    let request = environment.production ? this.http.get(url, options) : this.http.get(url); 
    
    return request;
  }

  getDefaultHeaders() {
    let headers = new HttpHeaders();
    //headers = headers.set('accept', 'application/json, text/plain, *');   
    //headers = headers.set('origin', 'tyreman');
    //headers = headers.set('Content-Type', 'application/json');
    return headers;    
  }

}

